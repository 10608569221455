export const config: ThemeConfig = {
	id: 't2054',
	title: i18n('Облачная'),
	adaptive_background: true,
	variants: ['v1', 'v2', 'v3'],
	select_css_alias(variant) {
		if (variant === 'v1') {
			return 'all';
		}
		return variant;
	},
	isDarkTheme: false,
};
