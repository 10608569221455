export const config: ThemeConfig = {
	id: 't1169',
	title: i18n('Звёздные Войны. Светлая сторона Силы'),
	variants: ['v1', 'v2'],
	select_css_alias() {
		return 'all';
	},
	adaptive_background: true,
	isDarkTheme: false,
};
