export const config: ThemeConfig = {
	id: 't1189',
	title: i18n('Погода'),
	isDarkTheme: false,
	adaptive_background: true,
	widget: {
		js: 'themes.widgets/weather/widgets.Weather',
		slot: 2928,
	},
	select_variant(model) {
		const data = model.widget.data;
		let variant = `v${data.sign ?? 1}`;

		if (data.now_icon === 'n') {
			variant += '.night';
		}

		return variant;
	},
	select_css_alias(variant) {
		const aliases: any = {
			v1: ['v9', 'v10', 'v11', 'v20'],
			v2: ['v12', 'v13', 'v17', 'v25', 'v29', 'v32', 'v33', 'v45', 'v47'],
			v3: ['v37', 'v38', 'v39', 'v40', 'v41', 'v42', 'v43'],
			v4: ['v14', 'v15', 'v16', 'v21', 'v23', 'v27', 'v30'],
			v5: ['v6', 'v18', 'v28', 'v48'],
			v7: [],
			v8: ['v19', 'v31', 'v34', 'v35', 'v36'],
			v22: [],
			v24: [],
			v26: [],
			v44: ['v46'],
		};

		Object.keys(aliases).forEach((key) => {
			aliases[`${key}.night`] = aliases[key].map(function (item: string) {
				return `${item}.night`;
			});
		});

		/* eslint no-labels: ["error", { "allowLoop": true }]*/
		loop: for (const alias in aliases) {
			if (alias === variant) {
				break;
			} else {
				for (let i = 0, l = aliases[alias].length; i < l; i++) {
					if (aliases[alias][i] === variant) {
						variant = alias;
						break loop;
					}
				}
			}
		}

		return variant;
	},
	verticalPreview: true,
};
