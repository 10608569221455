export const config: ThemeConfig = {
	id: 't3015',
	title: i18n('Зимняя погода'),
	adaptive_background: true,
	widget: {
		js: 'themes.widgets/weather/widgets.Weather',
		slot: 341187,
	},
	select_variant(model) {
		const data = model.widget.data;
		let variant = 'v1';

		if (data.now_icon === 'n') {
			variant += '.night';
		}
		return variant;
	},
	isDarkTheme: false,
};
