export const config: ThemeConfig = {
	id: 't5007',
	title: i18n('LOST ARK'),
	adaptive_background: true,
	variants: ['v1', 'v2', 'v3', 'v4'],
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: true,
};
