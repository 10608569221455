export const config: ThemeConfig = {
	id: 't1190',
	title: i18n('Чемпионат России по футболу'),
	widget: {
		js: 'themes.widgets/russianfootball/widgets.RussianFootball',
		slot: 3914,
	},
	adaptive_background: true,
	isDarkTheme: false,
};
