export const config: ThemeConfig = {
	id: 't2101',
	title: i18n('Стекло'),
	variants: ['all', 'v2', 'v3'],
	adaptive_background: true,
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: false,
};
