export const config: ThemeConfig = {
	id: 't5001',
	title: i18n('Рик и Морти'),
	adaptive_background: true,
	variants: ['v1', 'v2', 'v3'],
	select_css_alias(variant) {
		if (variant === 'v2') {
			return variant;
		}
		return 'all';
	},
	isDarkTheme: true,
};
