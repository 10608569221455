export const config: ThemeConfig = {
	id: 't1086',
	title: i18n('Цветы'),
	variants: ['all', 'v2', 'v3', 'v4', 'v5'],
	adaptive_background: true,
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: false,
};
