export const config: ThemeConfig = {
	id: 't5003',
	title: i18n('Звёздные Войны. Тёмная сторона Силы'),
	adaptive_background: true,
	variants: ['v1', 'v2'],
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: true,
};
