export const config: ThemeConfig = {
	id: 't1167',
	title: i18n('Delivery Club'),
	variants: ['v1', 'v2', 'v3', 'v4'],
	select_css_alias(variant) {
		if (variant === 'v1') {
			return 'all';
		}
		return variant;
	},
	isDarkTheme: false,
	adaptive_background: true,
};
