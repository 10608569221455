export const config: ThemeConfig = {
	id: 't3012',
	title: i18n('ФК Спартак Москва'),
	adaptive_background: true,
	variants: ['v1', 'v2'],
	select_css_alias() {
		return 'all';
	},
	widget: {
		slot: 89137,
	},
	isDarkTheme: false,
};
