export const config: ThemeConfig = {
	id: 't1151',
	title: i18n('Роскосмос'),
	adaptive_background: true,
	variants: ['v19', 'v20'],
	link: {
		href: 'http://r.mail.ru/n221589859',
		title: i18n('Роскосмос'),
		target: '_blank',
	},
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: false,
};
