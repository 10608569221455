export const config: ThemeConfig = {
	id: 't1155',
	title: i18n('ВКонтакте'),
	variants: ['v1', 'v2', 'v3'],
	adaptive_background: true,
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: false,
};
