export const config: ThemeConfig = {
	id: 't1115',
	title: i18n('Прованс'),
	variants: ['all', 'v2', 'v3', 'v4', 'v5', 'v6'],
	adaptive_background: true,
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: false,
};
