export const config: ThemeConfig = {
	id: 't5012',
	title: i18n('Новогодний вечер'),
	adaptive_background: true,
	isDarkTheme: true,
	widgetData: {
		text: i18n('Создать поздравление'),
		href: 'https://newyear.mail.ru/?utm_source=mail_theme&utm_medium=theme_dark&utm_campaign=newyear2022',
	},
};
