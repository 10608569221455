export const config: ThemeConfig = {
	id: 't2050',
	title: i18n('Безмятежность'),
	adaptive_background: true,
	widget: {
		slot: 6666,
	},
	isDarkTheme: false,
	verticalPreview: true,
};
