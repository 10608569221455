export const config: ThemeConfig = {
	id: 't5008',
	isDarkTheme: true,
	title: i18n('Новогодняя ночь'),
	adaptive_background: true,
	widgetData: {
		text: i18n('Создать поздравление'),
		href: 'https://r.mail.ru/n338994546',
	},
};
