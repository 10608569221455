export const config: ThemeConfig = {
	id: 't1157',
	title: i18n('Новый год'),
	adaptive_background: true,
	isDarkTheme: false,
	widgetData: {
		text: i18n('Создать поздравление'),
		href: 'https://r.mail.ru/n338994546',
	},
};
