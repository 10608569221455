export const config: ThemeConfig = {
	id: 't3002',
	title: i18n('Гороскоп'),
	adaptive_background: true,
	variants: ['all', 'v2', 'v3', 'v4', 'v5'],
	widget: {
		js: 'themes.widgets/horoscope/widgets.Horoscope',
		slot: 12082,
	},
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: false,
};
