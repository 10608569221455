export const config: ThemeConfig = {
	id: 't5006',
	title: 'American Dad! Apocalypse Soon',
	variants: ['v1', 'v2'],
	select_css_alias() {
		return 'all';
	},
	adaptive_background: true,
	isDarkTheme: true,
};
