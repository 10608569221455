export const config: ThemeConfig = {
	id: 't5005',
	title: 'Epicenter CS:GO',
	adaptive_background: true,
	variants: ['v1', 'v2'],
	select_css_alias() {
		return 'all';
	},
	isDarkTheme: true,
};
